import * as React from "react"
import { SEO } from "../components/seo"
import Layout from '../components/layout'
import { FaBolt } from 'react-icons/fa'
import { FaShieldAlt } from 'react-icons/fa'
import { FaPiggyBank } from 'react-icons/fa'
import { FaSearch } from 'react-icons/fa'
import { FaShoppingCart } from 'react-icons/fa'
import { FaMobile } from 'react-icons/fa'
import { FaThumbsUp } from 'react-icons/fa'
import { FaCog } from 'react-icons/fa'
import { FaEnvelope } from 'react-icons/fa'

const IndexPage = () => {
  return (
	<Layout>
		<main>
			<section id="hero">
				<h1>👋 Hello there! I'm Dylan Cliff and I'm pretty good at making websites.</h1>
			</section>
			<section id="intro">
				<p>
					If you are looking to get something created on the Internet, I am your guy! I build websites with the fastest, most secure tools. 
					Whether it's design, development, hosting, domains, security, speed, performance, accessiblity, seo, e-commerce... I can help!
					I can build you a website you can easily manage yourself OR I can save you the hassle and manage it 100% for you, for a reasonable cost!
					Contact me to learn more.
				</p>
				<div className="boxes">
					<div className="box" data-sal="slide-left" data-sal-delay="100" data-sal-easing="ease" data-sal-duration="500">
						<FaBolt />
						<h3>Lightning Fast</h3>
					</div>
					<div className="box" data-sal="slide-left" data-sal-delay="200" data-sal-easing="ease" data-sal-duration="500">
						<FaShieldAlt />
						<h3>Ultra Secure</h3>
					</div>
					<div className="box" data-sal="slide-left" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="500">
						<FaPiggyBank />
						<h3>Affordable</h3>
					</div>
					<div className="box" data-sal="slide-left" data-sal-delay="400" data-sal-easing="ease" data-sal-duration="500">
						<FaSearch />
						<h3>SEO Optimized</h3>
					</div>
					<div className="box" data-sal="slide-left" data-sal-delay="500" data-sal-easing="ease" data-sal-duration="500">
						<FaShoppingCart />
						<h3>E-Commerce</h3>
					</div>
					<div className="box" data-sal="slide-left" data-sal-delay="600" data-sal-easing="ease" data-sal-duration="500">
						<FaMobile />
						<h3>Responsive</h3>
					</div>
					<div className="box" data-sal="slide-left" data-sal-delay="700" data-sal-easing="ease" data-sal-duration="500">
						<FaThumbsUp />
						<h3>Easy To Use</h3>
					</div>
					<div className="box" data-sal="slide-left" data-sal-delay="800" data-sal-easing="ease" data-sal-duration="500">
						<FaCog />
						<h3>Customized</h3>
					</div>
				</div>
				<div className="spacer"></div>
				<h3>If you need help with your website or want a brand spanking new one, contact me today!</h3>
				<p><a className="button" href="/contact/"><FaEnvelope /> Contact Me</a></p>
			</section>
		</main>
	</Layout>
  )
}
export default IndexPage

export const Head = () => (
	<SEO />
)
